<template>
  <div class="index-qrcode">
    <!-- index:qr-code START -->
    <div class="qr-code">
      <div
        class="headline"
        v-html="$t('terminal.index.qrcode.headline')"
      ></div>
      <div
        class="description"
        v-html="$t('terminal.index.qrcode.description')"
      ></div>
      <div class="image">
        <img
          src="@/assets/images/index-scan-qrcode@3x.png"
          :alt="$t('terminal.index.qrcode.headline')"
        />
      </div>
    </div>
    <!-- index:qr-code END -->

    <!-- index:qr-code:ec START -->
    <div
      :class="[
        'help-ec',
        { 'hide' : loginError }
      ]"
    >
      <div class="help-ec-inner">
        <div class="actions">
          <!-- ec-karte START -->
          <a
            class="btn btn-default btn-default-white"
            v-html="$t('terminal.cart.paymentmethods.methods.ec.headline')"
            v-if="box && box.hasEcPayment === 1"
            v-on:click="$router.push({ path: '/cart/summary', query: { directWithCard: true } })"
            v-on:touchstart="touchStart($event)"
            v-on:touchend="touchEnd($event);"
            v-on:touchcancel="touchEnd($event)"
          ></a>
          <!-- ec-karte END -->

          <!-- back START -->
          <a
            class="btn btn-default btn-default-white"
            v-on:click="$router.go(-1)"
            v-on:touchstart="touchStart($event)"
            v-on:touchend="touchEnd($event);"
            v-on:touchcancel="touchEnd($event)"
            v-html="$t('terminal.index.qrcode.button.back')"
          ></a>
          <!-- back END -->

          <!-- abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
          >
            <span v-html="$t('terminal.index.cart.purchase.abort')"></span>
          </div>
          <!-- abort END -->
        </div>
      </div>
    </div>
    <!-- index:qr-code:ec END -->
  </div>
</template>

<script>
export default {
  name: 'cart-qrcode',
  props: {
    step: {
      type: Number,
    },
    box: {
      type: Object,
    },
    resetStoreCart: {
      type: Function,
    },
    loginError: {
      type: Boolean,
    },
  },
  methods: {
    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
};
</script>
